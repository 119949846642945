import { Link } from "@StarberryUtils";
import React, {useEffect, useState} from "react"
import {Button, ButtonGroup, Nav,Form, Container,Dropdown,Row,Col } from 'react-bootstrap';
import ContactCard from "../../Home/ContactCard/ContactCard"
// Images
import propertyImg01 from "../../../images/result/property-img01.jpg"
import propertyImg02 from "../../../images/result/property-img02.jpg"
import propertyImg03 from "../../../images/result/property-img03.jpg"
import propertyImg04 from "../../../images/result/property-img04.jpg"
import propertyImg05 from "../../../images/result/property-img05.jpg"
import propertyImg06 from "../../../images/result/property-img06.jpg"
import propertyImg07 from "../../../images/result/property-img07.jpg"
import propertyImg08 from "../../../images/result/property-img08.jpg"
import bgLogoImg from "../../../images/result/bg-logo-img.png"
import  './ResultList.scss';



const ResultList = ( props ) => {


  return(
<div className="result-list">
  <Container>
    <Row>
        <Col>
         <div className="animated filter_list" >
            <div className="result-header d-md-flex justify-content-between align-items-center">
                <h6 className="total-result">173 Properties For Sale in Doha, Qatar <i className="info-icon"></i></h6>
                <Form className="control-list d-md-flex align-items-center">
                    <Form.Group className="d-none d-md-block" controlId="formBasicCheckbox1">
                        <Form.Check type="checkbox" label="Include Sold" />
                    </Form.Group>
                    <Form.Group className="d-none d-md-block">
                        
                        <Dropdown as={ButtonGroup}>
                            <Dropdown.Toggle variant="link"><i className="sort-icon"></i> <span className="d-none d-lg-inline-block"> Newly Listed <i className="arrow-icon"></i></span></Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Form.Group>
                    <Form.Group>
                        <Link to="#"><i className="map-icon"></i> Map <span className="d-md-none d-lg-inline-block">View</span></Link>
                    </Form.Group>
                </Form>
            </div>
             </div>
        </Col>
    </Row>
    <div className="result-cards">
        <Row>
            <Col className="" md={6} lg={4}>
             <div className="animated">
                <ContactCard 
                    cardImg={propertyImg01}
                    cardTitle={"1 bedroom apartment for sale in Doha, Qatar"}
                    date={"1,265,670 AED"}
                    cardTag={"SSTC"}
                />
                 </div>
            </Col>
            <Col md={6} lg={4}>
             <div className="animated" delay={100}>
                <ContactCard 
                    cardImg={propertyImg02}
                    cardTitle={"Studio apartment for sale in Porto Arabia, Qatar"}
                    date={"865,500 AED"}
                    cardTag={""}
                />
                 </div>
            </Col>
            <Col md={6} lg={4}>
             <div className="animated" delay={200}>
                <ContactCard 
                    cardImg={propertyImg03}
                    cardTitle={"Studio apartment for sale in Viva Bahriyah"}
                    date={"1,100,203 AED"}
                    cardTag={""}
                />
                 </div>
            </Col>
            <Col md={6} lg={4}>
             <div className="animated">
                <ContactCard 
                    cardImg={propertyImg04}
                    cardTitle={"Studio Apartment For Rent In City Center Towers"}
                    date={"950,000 AED"}
                    cardTag={""}
                />
                 </div>
            </Col>
            <Col className=" sold" md={6} lg={4}>
             <div className="animated" delay={400}>
                <ContactCard 
                    cardImg={propertyImg05}
                    cardTitle={"Studio Apartment For Sale In Porto Arabia"}
                    date={"1,200,000 AED"}
                    cardTag={"Sold"}
                />
                 </div>
            </Col>
            <Col md={6} lg={4}>
             <div className="animated" delay={500}>
                <ContactCard 
                    cardImg={propertyImg06}
                    cardTitle={"Studio Apartment For Sale In The E18hteen"}
                    date={"983,203 AED"}
                    cardTag={""}
                />
                 </div>
            </Col>
            <Col md={6} lg={4}>
             <div className="animated" delay={600}>
                <div className="info-card">
                    <img className="cardbg-logo" src={bgLogoImg}  alt={`The Pearl Gates`} />
                    <div className="wrap">
                        <h2 className="info-title">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h2>
                        <p className="info-desc d-none d-xl-block">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus sapien a enim, vitae nibh et nunc ut egestas.</p>
                        <p className="info-desc d-xl-none">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        <Link to="/contact-us" className="btn btn-primary"><span>Contact Us</span></Link>
                    </div>
                </div> 
                 </div>
            </Col>
            <Col md={6} lg={4}>
             <div className="animated" delay={700}>
                <ContactCard 
                    cardImg={propertyImg07}
                    cardTitle={"Studio apartment for sale in Porto Arabia, Qatar"}
                    date={"799,384 AED"}
                    cardTag={""}
                />
                 </div>
            </Col>
            <Col className="d-none d-md-block" md={6} lg={4}>
             <div className="animated" delay={800}>
                <ContactCard 
                    cardImg={propertyImg08}
                    cardTitle={"Studio apartment for sale in Viva Bahriyah"}
                    date={"1,402,000 AED"}
                    cardTag={""}
                />
                 </div>
            </Col>
            <Col className="d-none d-md-block" md={6} lg={4}>
             <div className="animated" delay={900}>
                <ContactCard 
                    cardImg={propertyImg01}
                    cardTitle={"1 bedroom apartment for sale in Doha, Qatar"}
                    date={"1,265,670 AED"}
                    cardTag={""}
                />
                 </div>
            </Col>
            <Col className="d-none d-md-block" md={6} lg={4}>
             <div className="animated" delay={1000}>
                <ContactCard 
                    cardImg={propertyImg02}
                    cardTitle={"Studio apartment for sale in Porto Arabia, Qatar"}
                    date={"865,500 AED"}
                    cardTag={""}
                />
                 </div>
            </Col>
            <Col md={6} lg={4}>
             <div className="animated" delay={1100}>
                <ContactCard 
                    cardImg={propertyImg03}
                    cardTitle={"Studio apartment for sale in Viva Bahriyah"}
                    date={"1,100,203 AED"}
                    cardTag={""}
                />
                 </div>
            </Col>
            <Col md={6} lg={4}>
             <div className="animated" delay={1200}>
                <ContactCard 
                    cardImg={propertyImg04}
                    cardTitle={"Studio Apartment For Rent In City Center Towers"}
                    date={"950,000 AED"}
                    cardTag={""}
                />
                 </div>
            </Col>
            <Col className="d-none d-md-block"  md={6} lg={4}>
             <div className="animated" delay={1300}>
                <ContactCard 
                    cardImg={propertyImg05}
                    cardTitle={"Studio Apartment For Sale In Porto Arabia"}
                    date={"1,200,000 AED"}
                    cardTag={""}
                />
                 </div>
            </Col>
            <Col className="d-md-none d-lg-block" md={6} lg={4}>
             <div className="animated" delay={1400}>
                <ContactCard 
                    cardImg={propertyImg06}
                    cardTitle={"Studio Apartment For Sale In The E18hteen"}
                    date={"983,203 AED"}
                    cardTag={""}
                />
                 </div>
            </Col>
        </Row>
    </div>
  </Container>

    
  </div>
)
  }



export default ResultList